import React from "react"
import JoynerHand from "../components/JoynerHand"
import { Layout } from "../components/Layout"

const JoynerHandPage = () => {
  return (
    <Layout>
      <JoynerHand />
    </Layout>
  )
}

export default JoynerHandPage
