import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary } from "../Section"
import { HrefButtonInverse } from "../Button"
import JoyerHandHero from "./JoynerHandHero"

import SvgImage from "../SvgImage"
import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"
import LogoTjh from "../../images/logo-tjh.svg"

const StyledJoyerHandHero = styled(JoyerHandHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`

const JoyerHand = ({ sectionImg }) => {
  return (
    <section>
      <StyledJoyerHandHero backgroundHeight={550} centered>
        <h1>MICHAEL S. JOYNER, MD</h1>
        <h2>Boca Raton Hand Surgeon</h2>
        <blockquote>
          "THE HUMAN HAND ALLOWS THE MIND TO REVEAL ITSELF" - Maria Montessori
        </blockquote>
      </StyledJoyerHandHero>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoTjh}
              altText="The Joyner Hand Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>
              “COMPLETE HAND SURGERY – CARE FROM RECONSTRUCTION TO REJUVENATION”
            </h3>
            <p>
              The hand and wrist are very complex; therefore, seeing a hand
              specialist can yield the best solution for conditions of the hand,
              wrist and arm. The Joyner Hand provides a number of surgical and
              non-surgical procedures for a variety of hand and arm conditions.
            </p>
            <p>
              We at The Joyner Hand take pride in your hands and we promote hand
              rejuvenation. Surgical and non-surgical techniques can help the
              appearance of your hands.
            </p>
            <HrefButtonInverse href="https://www.thejoynerhand.com">
              Take me to The Joyner Hand
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default JoyerHand
